
<script>
    import _ from 'lodash';
    export default {
        data: function () {
            return {
                extractedFilters: null,
            }
        },
        created() {
            console.log("HAS FILTERS");
            if(this.$route.query.filters){
                this.extractedFilters = JSON.parse(atob(this.$route.query.filters));
                if(this.request !== undefined) {
                    var newFilters = []
                    _.each(this.extractedFilters,(filter,filterKey) => {
                        newFilters.push({api_key: filterKey, 'value': filter});
                    });
                    this.request = newFilters;
                }
            }
            console.log("MIXIN REQUEST",this.request);
        }
    }
</script>
