<template>
    <div class="p-2">
        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
            <div class="flex-wrap">
                <router-link id="create-user" style="font-size: 14px;" class="btn btn-sm btn-outline-primary d-inline-block"
                             :to="{ name : 'userAdd'}">
                    <b-icon-plus></b-icon-plus>
                    Create new user
                </router-link>
            </div>
        </div>
        <div v-if="shouldShowNormalUsers()">
            <div class="row no-gutters">
                <div class="col-3 pr-2">
                    <b-card>
                        <filters-loader :current-filters="request" endpoint="user-filters"
                                        v-on:filters_updated="(filters) => { filtersUpdated(filters)}"/>
                    </b-card>
                </div>
                <div class="col-9">
                    <paginated-result-loader endpoint="users" cell-type="users"
                                             :filters="request"></paginated-result-loader>
                </div>
            </div>
        </div>

        <div class="px-3" v-else>
            <h4>Grant access to workspace users</h4>
            <p class="">Please use the boxes below to add users from your workspace to the Access Right to Work system</p>
            <workspace-users :update-ref="request" ></workspace-users>
        </div>

        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false"><b-icon-x-circle></b-icon-x-circle></a>
            </div>
            <router-view></router-view>
        </b-modal>
    </div>
</template>

<script>
    import FiltersLoader from "../../../components/FiltersLoader";
    import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
    import _ from 'lodash'
    import WorkspaceUsers from "../../../components/users/WorkspaceUsers";
    import filterExtractMixin from "../../../components/mixins/filterExtractMixin";

    export default {
        name: "users",
        components: {WorkspaceUsers, PaginatedResultLoader, FiltersLoader},
        mixins : [filterExtractMixin],
        data: function () {
            return {
                showModal: false,
                request: [],
                paginatedResponse: {},
                super : false,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Users',
                        active: true
                    }
                ]
            }
        },
        created() {
            this.super = (this.$router.currentRoute.name === "usersAdmin" && this.$store.getters.isSuper);
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if(newVal.params.data_updated !== undefined) {
                        this.request = _.clone(this.request)
                    }
                    this.super = (this.$router.currentRoute.name.indexOf('Admin') !== -1 && this.$store.getters.isSuper);
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal) {
                    this.request = _.clone(this.request);
                    if(this.$router.currentRoute.name !== 'users' && this.$router.currentRoute.name !== 'usersAdmin') {
                        if(this.$router.currentRoute.name.indexOf("Admin") !== -1) {
                            this.$router.replace({'name': 'usersAdmin', params: {'data_updated': true}}).catch((err) => {});
                        } else {
                            this.$router.replace({'name': 'users', params: {'data_updated': true}}).catch((err) => {});
                        }
                    }
                }
                if(newVal !== oldVal && !newVal) {
                    this.request = _.clone(this.request)
                }
            }
        },
        methods: {
            filtersUpdated(filters) {
                this.request = filters;
            },
            shouldShowNormalUsers() {
                if(this.$store.getters.isWorkspaceAccount) {
                    console.log("WORKSPACE ACCOUNT");
                }
                return !this.$store.getters.isWorkspaceAccount || this.super;
            }
        }

    }
</script>
