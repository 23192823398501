<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div class="row ">
            <div :class="this.$store.getters.isOrgSetup ? 'col-4 mt-3' : 'col-6 mt-3'">
                <div class="card">
                    <div class="d-flex border-bottom align-items-center">
                        <h6 class="p-3 font-weight-bold  m-0 flex-fill ">Organisation users</h6>
                    </div>
                    <div class="p-0 position-relative">
                        <div class="position-absolute d-flex justify-content-center align-items-center flex-column px-3"
                             style="left: 0; bottom: 0; top: 0;">
                            <b-icon-search @click="" class="text-gray"></b-icon-search>
                        </div>
                        <b-form-input
                                class="border-bottom border-top-0 border-right-0 border-left-0 rounded-0 m-0 shadow-sm"
                                style="padding-left: 40px; padding-right: 40px;" debounce="300"
                                v-model="unassignedSearch"
                                placeholder="Search users"/>
                        <div v-if="unassignedSearch != null && unassignedSearch.length > 0" @click="resetUnassigned"
                             class="position-absolute d-flex justify-content-center align-items-center flex-column px-2"
                             style="right: 0; bottom: 0; top: 0;">
                            <b-icon-x class="text-gray"></b-icon-x>
                        </div>
                    </div>
                    <div class="" style="height: 350px; overflow: auto;">
                        <paginated-result-loader v-on:delete="resetAssigned" endpoint="workspace/users"
                                                 cell-type="workspace-users"
                                                 :filters="unassignedRequest"></paginated-result-loader>
                    </div>
                </div>
            </div>
            <div :class="this.$store.getters.isOrgSetup ? 'col-8 mt-3' : 'col-6 mt-3'">
                <div class="card">
                    <h6 class="p-3 font-weight-bold border-bottom text-info m-0">Added users</h6>
                    <div class="p-0 d-flex border-bottom shadow-sm">
                        <div class="position-relative flex-fill">
                            <div class="position-absolute d-flex justify-content-center align-items-center flex-column px-3"
                                 style="left: 0; bottom: 0; top: 0;">
                                <b-icon-search class="text-gray"></b-icon-search>
                            </div>
                            <b-form-input
                                    class="border-0 rounded-0 m-0 "
                                    style="padding-left: 40px; padding-right: 40px;" debounce="300"
                                    v-model="assignedSearch"
                                    placeholder="Search users"/>
                            <div v-if="assignedSearch != null && assignedSearch.length > 0" @click="resetAssigned"
                                 class="position-absolute d-flex justify-content-center align-items-center flex-column px-2"
                                 style="right: 0; bottom: 0; top: 0;">
                                <b-icon-x class="text-gray"></b-icon-x>
                            </div>
                        </div>
                        <div class="flex-wrap border-left">
                            <b-dropdown right variant="link" toggle-class="text-decoration-none">
                                <template v-slot:button-content>
                                    <b-icon-filter :class=""></b-icon-filter>
                                    <b-badge pill v-if="userTypes.length > 0">{{ userTypes.length }}</b-badge>
                                </template>
                                <div class="px-3">
                                    <b-form-group label="User types:">
                                        <b-form-checkbox-group v-model="userTypes">
                                            <b-form-checkbox value="user">Users</b-form-checkbox>
                                            <b-form-checkbox value="supervisor">Supervisors</b-form-checkbox>
                                            <b-form-checkbox value="admin">Admins</b-form-checkbox>
                                            <b-form-checkbox value="billing">Billing</b-form-checkbox>
                                            <b-form-checkbox value="unapproved">Unapproved devices</b-form-checkbox>
                                        </b-form-checkbox-group>
                                    </b-form-group>
                                </div>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="" style="height: 350px; overflow: auto;">
                        <paginated-result-loader v-on:delete="resetUnassigned" endpoint="workspace/users"
                                                 cell-type="workspace-users"
                                                 :filters="assignedRequest"></paginated-result-loader>
                    </div>
                </div>
            </div>
        </div>

        <div class="alert alert-info mt-4">
            <strong>Need to add a user not setup in Access Workspace?</strong> Add users below, they will receive an email enabling them to join your Access Workspace and start using Right to Work straight away.
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="d-flex border-bottom align-items-center">
                        <h6 class="p-3 font-weight-bold  m-0 flex-fill ">Pending users</h6>
                        <router-link :to="this.$store.getters.isOrgSetup ? { name : 'userAdd'} : { name : 'setupUserAdd'}">
                            <b-icon-plus-circle class="text-success mr-3"></b-icon-plus-circle>
                        </router-link>
                    </div>
                    <div class="p-0 position-relative">
                        <div class="position-absolute d-flex justify-content-center align-items-center flex-column px-3"
                             style="left: 0; bottom: 0; top: 0;">
                            <b-icon-search @click="" class="text-gray"></b-icon-search>
                        </div>
                        <b-form-input
                                class="border-bottom border-top-0 border-right-0 border-left-0 rounded-0 m-0 shadow-sm"
                                style="padding-left: 40px; padding-right: 40px;" debounce="300"
                                v-model="unassignedSearch"
                                placeholder="Search users"/>
                        <div v-if="unassignedSearch != null && unassignedSearch.length > 0" @click="resetUnassigned"
                             class="position-absolute d-flex justify-content-center align-items-center flex-column px-2"
                             style="right: 0; bottom: 0; top: 0;">
                            <b-icon-x class="text-gray"></b-icon-x>
                        </div>
                    </div>
                    <div class="" style="height: 350px; overflow: auto;">
                        <paginated-result-loader v-on:delete="resetPending" endpoint="users"
                                                 cell-type="usersPending"
                                                 :filters="pendingRequest"></paginated-result-loader>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

    import {roleToVariant} from "../../modules/helpers/helpers";
    import {deleteResource, getResource} from "../../modules/api/methods";
    import {userDetail, userImpersonate, userPasswordReset, workspaceUsers} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";
    import PaginatedResultLoader from "../PaginatedResultLoader";
    import _ from 'lodash'

    export default {
        name: "WorkspaceUsers",
        components: {PaginatedResultLoader, CellLoader},
        props: {
            item: {},
            updateRef: null
        },
        data: function () {
            return {
                userTypes: [],
                unassignedSearch: null,
                assignedSearch: null,
                pendingSearch: null,
                isLoading: false,
                unassignedRequest: [{api_key: 'status', 'value': 'unassigned'}],
                assignedRequest: [{api_key: 'status', 'value': 'assigned'}],
                pendingRequest: [{api_key: 'pending', 'value': 1}],
            }
        },
        created() {

        },
        watch: {
            updateRef: {
                deep: true,
                immediate: false,
                handler(oldVal, newVal) {
                    console.log("LOADING " + this.endpoint + " from filters");
                    this.doUnassignedRequest(newVal);
                    this.doAssignedRequest(newVal);
                    this.doPendingRequest(newVal);
                }
            },
            pendingSearch: function (newVal, oldVal) {
                this.doPendingRequest(newVal);
            },
            unassignedSearch: function (newVal, oldVal) {
                this.doUnassignedRequest(newVal);
            },
            assignedSearch: function (newVal, oldVal) {
                this.doAssignedRequest(newVal);
            },
            userTypes: function (newVal, oldVal) {
                this.doAssignedRequest(this.assignedSearch);
            },
        },
        methods: {
            roleVariant: function (role) {
                return roleToVariant(role)
            },
            resetPending: function () {
                this.pendingSearch = null;
                this.doPendingRequest();
            },
            resetAssigned: function () {
                this.assignedSearch = null;
                this.doAssignedRequest();
            },
            resetUnassigned: function () {
                this.unassignedSearch = null;
                this.doUnassignedRequest();
            },
            doUnassignedRequest: function (search) {
                var request = [{api_key: 'status', 'value': 'unassigned'}];
                if (search != null && search.length > 0) {
                    request.push({api_key: 'keywords', 'value': search});
                }
                this.unassignedRequest = request;
            },
            doAssignedRequest: function (search) {
                var request = [{api_key: 'status', 'value': 'assigned'}];
                if (search != null && search.length > 0) {
                    request.push({api_key: 'keywords', 'value': search});
                }
                if (this.userTypes.length > 0) {
                    request.push({api_key: 'roles', 'value': this.userTypes});
                }
                this.assignedRequest = request;
            },
            doPendingRequest: function (search) {
                var request = [{api_key: 'pending', 'value': 1}];
                if (search != null && search.length > 0) {
                    request.push({api_key: 'keywords', 'value': search});
                }
                this.pendingRequest = request;
            },
        },
        computed: {
            hasAccess: function () {
                var hasAccess = false;
                _.each(this.item.roles, function (role) {
                    if (role.app_access === 1) hasAccess = true;
                });
                return hasAccess;
            }
        }

    }
</script>

<style scoped>

</style>
