<template>
    <div>
        <surround-loader :is-loading="isLoading">
            <div v-if="!isLoading" class="filter-list">
                <api-form-input v-on:field_updated="filterUpdated" v-for="(filter,key) in filters" :field="filter"
                                v-bind:key="key"></api-form-input>
            </div>
        </surround-loader>
    </div>

</template>

<script>
    import ApiFormInput from "./form_inputs/ApiFormInput"
    import {getEndpoint} from "../modules/api/methods";
    import _ from "lodash";
    import SurroundLoader from "./SurroundLoader";

    export default {
        name: "FiltersLoader",
        props: {
            endpoint: null,
            currentFilters: {
                type: Array,
                default: null
            }
        },
        data: function () {
            return {
                filters: [],
                isLoading: false
            }
        },
        components: {
            SurroundLoader,
            'api-form-input': ApiFormInput
        },
        created() {
            this.loadFilters()
        },
        methods: {
            filterUpdated() {
                this.$emit('filters_updated', this.filters);
            },
            loadFilters() {
                this.isLoading = true;
                var vm = this;
                getEndpoint(this.endpoint).then((resp) => {
                    var filters = resp.data.success.data;
                    //GOT TO GET CACHED FILTERS TO RETAIN THEM ON BACK AND FORTH IN BROWSERS
                    var finalFilters = [];
                    _.each(filters, function (filter) {
                        var current = _.filter(vm.currentFilters, function (obj) {
                            return obj.api_key === filter.api_key;
                        });

                        if (current.length > 0) {
                            filter.value = current[0].value
                        }
                        finalFilters.push(filter);
                    });
                    this.filters = finalFilters;
                    console.log("CURRENT FILTERS ARE ", this.filters);
                    this.isLoading = false;
                }).catch((error) => {
                    console.log(error)
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
